@font-face {
    font-family: 'Hacen Tunisia';
    src: local('Hacen Tunisia Light'), url('./theme/fonts/Hacen_Tunisia_Lt.ttf') format('truetype');
    font-weight: 300; /* Light */
  }
  
  @font-face {
    font-family: 'Hacen Tunisia';
    src: local('Hacen Tunisia'), url('./theme/fonts/Hacen_Tunisia.ttf') format('truetype');
    font-weight: 400; /* Regular */
  }
  
  @font-face {
    font-family: 'Hacen Tunisia';
    src: local('Hacen Tunisia Bold'), url('./theme/fonts/Hacen_Tunisia_Bd.ttf') format('truetype');
    font-weight: 700; /* Bold */
  }
  